.contenedor-document {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contenedor-document h1 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.contenedor-document p {
  font-size: 16px;
  text-align: center;
  color: #555;
}

.upload-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.form-control {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #fbad18;
  outline: none;
}

.dropzone {
  border: 2px dashed #ddd;
  padding: 30px;
  text-align: center;
  cursor: pointer;
}

.dropzone.dragging {
  border-color: #333;
  background-color: #f0f0f0;
}

.file-list {
  margin-top: 20px;
}

.file-list ul {
  list-style: none;
  padding: 0;
}

.file-list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.file-list button {
  background: none;
  border: none;
  color: #fbad18;
  cursor: pointer;
  font-size: 14px;
}

/* Hacer responsive */
@media (max-width: 768px) {
  .contenedor-document {
    padding: 15px;
    max-width: 90%;
  }

  .contenedor-document h1 {
    font-size: 20px;
  }

  .contenedor-document p {
    font-size: 14px;
  }

  .upload-form {
    gap: 10px;
  }

  .form-control {
    font-size: 14px;
  }

  .btn-submit {
    padding: 8px 16px;
    font-size: 14px;
  }

  .dropzone {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .contenedor-document {
    padding: 10px;
    max-width: 100%;
  }

  .contenedor-document h1 {
    font-size: 18px;
  }

  .contenedor-document p {
    font-size: 12px;
  }

  .upload-form {
    gap: 8px;
  }

  .form-control {
    font-size: 12px;
  }

  .btn-submit {
    padding: 6px 12px;
    font-size: 12px;
  }

  .dropzone {
    padding: 15px;
  }
}

.center-text {
  text-align: center;
}